import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import countries from 'projects/backoffice/src/assets/data/data-list-country.json';

export interface Country {
  name_en: string;
  name_es: string;
  continent_en: string;
  continent_es: string;
  capital_en: string;
  capital_es: string;
  dial_code: string;
  code_2: string;
  code_3: string;
  tld: string;
}

@Component({
  selector: 'cad-send-document-whatsapp',
  templateUrl: './send-document-whatsapp.component.html',
  styleUrls: ['./send-document-whatsapp.component.scss'],
})
export class SendDocumentWhatsappComponent implements OnInit {
  dataCountry = countries.countries;
  filterOptionsDataCountry = this.dataCountry;
  form!: FormGroup;
  @Input() document: any;
  sendingDocument: string = 'Envío de documento';
  countryCode: string = 'Código país';
  numberPhone: string = 'Número de celular';
  saveButton: string = 'Guardar';
  closeButton: string = 'Cerrar';
  messageError: string = 'El campo es requerido';
  itemCountry: any = {};
  code: string = ' ';
  filteredContry: Observable<Country[]>;

  constructor(
    private _fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private SendDocumentModal: MatDialogRef<SendDocumentWhatsappComponent>
  ) {
    const formControls = {
      countryCode: new FormControl('', [Validators.required]),
      numberPhone: new FormControl('', [Validators.required, Validators.pattern(/^([0-9])/)]),
    };
    this.form = this._fb.group(formControls);
  }

  ngOnInit(): void {
    this.form.get('countryCode').valueChanges.subscribe(response => {
      this.filterDataCountry(response);
      this.itemCountry = this.dataCountry.filter(item => response == item.name_es);
      if (this.itemCountry.length == 1) {
        this.code = this.itemCountry[0].dial_code;
      }
    });
  }

  filterDataCountry(data) {
    this.filterOptionsDataCountry = this.dataCountry.filter(item => {
      return item.name_es.toLowerCase().indexOf(data.toLowerCase()) > -1;
    });
  }

  save(): void {
    const sendDocument = {
      numberPhone: this.form.value.numberPhone,
      countryCode: this.code,
      documents: this.data.documents,
    };
    let nameFiles = '';
    let fileUrls = '';
    for (let i = 0; i < sendDocument.documents.length; i++) {
      nameFiles += sendDocument.documents[i].fileName + '%0A';
      const cutFile = sendDocument.documents[i].file.substring(0, sendDocument.documents[i].file.indexOf('&'));
      fileUrls += cutFile + '%0A';
    }

    window.open(
      'https://api.whatsapp.com/send?phone=' +
        sendDocument.countryCode +
        sendDocument.numberPhone +
        '&text=Estimado%20cliente%2C%0ASe%20env%C3%ADa%20el%20documento%20%0A' +
        nameFiles +
        '.%20Para%20ver%20click%20en%20el%20siguiente%20enlace%3A%0A' +
        fileUrls
    );
    this.SendDocumentModal.close();
  }
}
