<div class="container">
    <form [formGroup]="unsuscribeForm">
        <div class="titleModal" id="titleModal">
            <span class="tsp-font-size-modal-titles ">{{TitleModal}}</span>
        </div>
        <div class="row">
            <div class="question">
                <span class="tsp-font-size-default-content unsuscribe-label">{{Question}}</span>
            </div>
            <div class=" detail">
                <div class="labelDetail">
                    <mat-label class="tsp-font-size-default-content unsuscribe-label">{{SubTitleLabel}}
                    </mat-label>
                </div>
                <div>
                    <mat-form-field class="generalMatForm" appearance="fill">
                        <textarea rows="3" matInput class="detailTextArea tsp-font-size-default-content"
                            formControlName="Observation"></textarea>
                        <mat-error *ngIf="unsuscribeForm.controls.Observation.errors?.required"
                            class="tsp-font-size-default-content ">
                            {{MessageError}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="btns">
                <div class="btnOk ">
                    <button mat-flat-button mat-button mat-dialog-close (click)="Ok()"
                        [disabled]="!this.unsuscribeForm.valid" class="tsp-font-size-buttons tsp-button-success">
                        {{BtnOk}}
                    </button>
                </div>
                <div class="btnCancel">
                    <button mat-flat-button mat-button mat-dialog-close class="tsp-font-size-buttons tsp-button-delete">
                        {{BtnCancel}}
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>