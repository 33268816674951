<p-toast></p-toast>
<p-toolbar>
    <div class="p-toolbar-group-left">
        <button class="button-dowload" mat-flat-button (click)="dowloadFiles($event)"
            [disabled]="!selectedItems || !selectedItems.length">
            <mat-icon>save_alt</mat-icon>
        </button>
        <span class="p-toolbar-separator"></span>
        <button class="button-whatsapp" mat-flat-button (click)="sendFiles($event)"
            [disabled]="!selectedItems || !selectedItems.length">
            <img src="assets/images/pictures/icon-whatsapp.svg" class="iconWhatsapp" />
        </button>
    </div>


</p-toolbar>
<p-table [value]="Files" [(selection)]="selectedItems" [selectionPageOnly]="true" styleClass="p-datatable-customers"
    class="tsp-table-container table" dataKey="file">
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 4rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th pSortableColumn="Documents" style="min-width:30rem;text-transform: uppercase">
                {{Title}}
            </th>
        </tr>

    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
        <tr>
            <td>
                <p-tableCheckbox [value]="rowData">
                </p-tableCheckbox>
            </td>
            <td class="data-columns tsp-font-size-default-content">
                <label>{{rowData.fileName}}&nbsp;</label>
            </td>
        </tr>
    </ng-template>
</p-table>