<p-table #dt1 [value]="items" selectionMode="single" [(selection)]="selectedItem" dataKey="id"
    styleClass="p-datatable-customers" [rows]="10" [paginator]="true" [columns]="columns"
    class="tsp-table-container table" [scrollable]="scrollable">
    <ng-template pTemplate="header">
        <tr>
            <th class="column-options table-font-header">
                Nº
            </th>
            <th *ngFor="let column of columns" pSortableColumn="{{column.field}}"
                class="column-options table-font-header">
                {{column.header}}</th>
        </tr>
        <tr>
            <th class="column-options">
            </th>
            <th *ngFor="let column of columns">
                <input pInputText type="text" *ngIf="column.field != 'Alert'"
                    (input)="dt1.filter($any($event.target)?.value, column.field, 'contains')"
                    [value]="$any(dt1).filters[column.field]?.value"
                    class="p-column-filter tsp-input-filter  table-font-content">
            </th>
            <th class="column-options">
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-customer let-rowData let-columns="columns" let-rowIndex="rowIndex"
        let-value="values">
        <tr [pSelectableRow]="customer">
            <td>
                {{rowIndex + 1}}
            </td>
            <td *ngFor="let col of columns; let i=index" class="column-options">
        <tr *ngIf="col.field != 'alert'" class="data-columns table-font-content">{{ rowData[col.field]}}</tr>
        <tr class="data-columns table-font-content" *ngIf="col.field == 'alert'" [ngClass]="{'status-red':rowData.state == '0', 'status-ambar': rowData.state == '1'
                     }">{{ rowData['state'] == 0 ? 'Vencido hace '+ rowData['numberOfDays']+' días' : 'Próximo a
            vencer en '+ rowData['numberOfDays']+' días'}}
        </tr>
        </td>


        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="4">No customers found.</td>
        </tr>
    </ng-template>
</p-table>