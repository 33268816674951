<div class="filter-container">
  <label class="title-filter tsp-font-size-default-title">{{InputLabel}}</label><br>
  <mat-form-field class="chipsSelectContainer">
    <mat-label class="tsp-font-size-default-content">{{InputLabel}}</mat-label>
    <mat-select [formControl]="itemsControl" multiple>
      <mat-select-trigger>
        <mat-chip-list>
          <mat-chip *ngFor="let item of itemsControl.value" [removable]="true" (removed)="onItemRemoved(item)"
            class="tsp-font-size-default-content">
            {{ item.name }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </mat-select-trigger>
      <mat-option *ngFor="let item of items" [value]="item" (click)="add()"
        class="tsp-font-size-default-content">{{item.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-checkbox class="check-box" (change)="OnChange($event)" [checked]="allSelected"
    class="tsp-font-size-default-content">{{InputCheckLabel}}</mat-checkbox>
</div>
<br />