<p-table [value]="Licenses" [(selection)]="selectedItems" [selectionPageOnly]="true" styleClass="p-datatable-customers"
    class="tsp-table-container table" dataKey="id" [columns]="columns">
    <ng-template pTemplate="header">
        <tr>
            <th class="column-options table-font-header">
                {{numberLabel}}
            </th>
            <th *ngFor="let column of columns" pSortableColumn="{{column.field}}"
                class="column-options table-font-header">
                {{column.header}}</th>
            <th class="column-options table-font-header">
                {{licenses}}
            </th>
            <th class="column-options table-font-header">
                {{optionsLabel}}
            </th>
        </tr>

    </ng-template>
    <ng-template pTemplate="body" let-customer let-rowData let-columns="columns" let-rowIndex="rowIndex">
        <tr [pSelectableRow]="customer">
            <td class="tsp-font-size-default-content tsp-index">
                {{rowIndex + 1}}
            </td>
            <td *ngFor="let col of columns; let i=index" class="column-options">

        <tr class="data-columns tsp-font-size-default-content">
            {{
            rowData[col.field]}}
        </tr>
        </td>
        <td>
            <div mat-raised-button class="container-document">
                <mat-icon class="iconFile">description</mat-icon>
                <span class="red">{{rowData.documents.length}} </span>
            </div>
        </td>
        <td class="buttons-container row">
            <div *ngIf="Edit && rowData.id !=''" mat-raised-button class="col-12 col-md-6 col-lg-4 container-buttons">
                <button [disabled]="hideButtons" class="button-actions" (click)="editAction(rowIndex)">
                    <mat-icon class="tsp-icons">edit</mat-icon>
                </button>
            </div>
            <div mat-raised-button class="col-12 col-md-6 col-lg-4 container-buttons">
                <button [disabled]="hideButtons" class="button-actions-delete" (click)="deleteAction(rowIndex)">
                    <mat-icon class="tsp-icons">delete</mat-icon>
                </button>
            </div>
        </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="4"> {{ notLicenses }}</td>
        </tr>
    </ng-template>
</p-table>