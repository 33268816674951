import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cad-register-master',
  templateUrl: './register-master.component.html',
  styleUrls: ['./register-master.component.scss']
})
export class RegisterMasterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
