import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SendDocumentWhatsappComponent } from '../send-document-whatsapp/send-document-whatsapp.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'cad-viewer-file',
  templateUrl: './viewer-file.component.html',
  styleUrls: ['./viewer-file.component.scss'],
})
export class ViewerFileComponent implements OnInit {
  @Input() Files: any;
  @Input() Title: string = 'Archivos';

  selectedItems: any[];
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  dowloadFiles(event) {
    event.preventDefault();
    for (var i = 0; i < this.selectedItems.length; i++) {
      window.open(this.selectedItems[i].file);
      setTimeout(function (path) {
        window.open(path);
      }, this.selectedItems[i].file);
    }
  }
  sendFiles(event) {
    event.preventDefault();
    const dialogRef = this.dialog.open(SendDocumentWhatsappComponent, {
      disableClose: true,
      data: {
        documents: this.selectedItems,
      },
    });
    dialogRef.backdropClick();
  }
}
